<template>
  <div class="wrapper">
    <div class="inner">
      <div class="video-wrapper">
        <video
          id="Video"
          width="100%"
          height="100%"
          poster="@/assets/poster-ex.jpg"
          oncontextmenu="return false;"
          style="object-fit: fill"
        >
          <source
            src="https://1257122416.vod2.myqcloud.com/28b0e9c8vodsh1257122416/c4f13433387702297001958622/f0.mp4"
          />
          Your browser does not support the video tag.
        </video>
        <img
          v-if="startBtnVisible"
          class="start-btn"
          src="@/assets/video-start-btn.png"
          alt=""
          @click="play"
        />
        <img
          v-if="stopBtnVisible"
          class="stop-btn"
          src="@/assets/video-stop-btn.png"
          alt=""
          @click="pause"
        />
      </div>
      <div class="content">
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
const content =
  "第二届818财税节，中国财税服务博览会（以下简称“税博会“）于2021年9月23日在中国上海隆重召开。税博会是财税服务行业颇具影响力的超级盛会，拥有前瞻性的主题内容、高规格的嘉宾阵容和多形式的交互平台。通过财税数智理念 、创新商业模式、先进经营策略等多维度的分享展示、链接合作，拓宽财税人认知广度与宽度，打破财税服务机构经营 限制，引领财税服务行业发展趋势，推动财税服务行业向荣共生。";
export default {
  data() {
    return {
      startBtnVisible: true,
      stopBtnVisible: false,
      content: content,
    };
  },
  methods: {
    play() {
      const videoElement = document.getElementById("Video");
      videoElement.play();
      this.startBtnVisible = false;
      this.stopBtnVisible = true;
    },
    pause() {
      const videoElement = document.getElementById("Video");
      videoElement.pause();
      this.startBtnVisible = true;
      this.stopBtnVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 169px;
  margin-bottom: 200px;
  .inner {
    background: #ffffff;
    border-radius: 10px;
    height: 420px;

    .video-wrapper {
      float: left;
      border-radius: 10px;
      margin-left: 30px;
      width: 620px;
      height: 420px;
      position: relative;
      top: -70px;
      video {
        border-radius: 10px;
      }
      .start-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
      .stop-btn {
        position: absolute;
        left: 44px;
        bottom: 0;
        transform: translate(-50%, -50%);
        cursor: pointer;
        width: 40px;
        height: 40px;
      }
    }
    .content {
      float: left;
      width: 458px;
      font-size: 16px;
      line-height: 38px;
      margin-left: 50px;
      padding-top: 110px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 50px;
        left: 0;
        width: 58px;
        height: 4px;
        background: #3b40ff;
        border-radius: 2px;
      }
    }
  }
}
</style>
