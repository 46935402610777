<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  },
  methods: {
    
  }
}
</script>

<style lang="less">
.video-js {
    width: 100% !important;
    height: 100%  !important;
}

.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block;
}
.video-js .vjs-big-play-button {
  background-color: rgba(0,0,0,0.6);
  font-size: 4em;
  border-radius: 50%;
  height: 2em !important;
  line-height: 2em !important;
  width: 2em !important;
  left: calc(103%/2);
  top: calc((100%)/2);
  transition: transform 0.5s ease;
}

 .video-js .vjs-big-play-button:hover{
  transform: scale(1.2,1.2);
}
.video-js .vjs-play-progress:before {
  top: -0.4em !important;
}
</style>>
