<template>
  <a-layout class="page-layout">
    <a-layout-header class="page-header">
      <div class="header-wrapper">
        <div class="logo">
          <img src="@/assets/site-logo.png" alt="" />
        </div>
        <a-menu mode="horizontal" :default-selected-keys="tabKey" theme="dark">
          <a-menu-item key="1"> <a href="#">首页</a> </a-menu-item>
          <a-menu-item key="4">
            <a @click="scrollToAnchor('#Schedule')">大会议程</a>
          </a-menu-item>
          <a-menu-item key="5">
            <a @click="scrollToAnchor('#Guest')">重磅嘉宾</a>
          </a-menu-item>
          <a-menu-item key="6">
            <a @click="scrollToAnchor('#dataPoints')">资料看点</a>
          </a-menu-item>
          <!-- <a-menu-item key="5"> <a>往届回顾</a> </a-menu-item>
          <a-menu-item key="6"> <a>专题栏目</a> </a-menu-item> -->
        </a-menu>
        <div class="header-tail">
          <!-- <img src="@/assets/playback.png" alt="" /> -->
          <div class="playback" @click="goVideo()">观看回放</div>
          <span @click="login">登录</span>
        </div>
      </div>
    </a-layout-header>
    <a-layout-content class="page-content">
      <div class="banner" id="banner">
        <img class="bg" src="@/assets/banner.png" alt="" />
        <div class="review" @click="goVideo()">观看回放</div>
      </div>
      <h1 id="Desc" class="home-title meeting-description">
        <div class="english-name">CONFERENCE INTRODUCTION</div>
        大会简介
      </h1>
      <Description />
      <div class="highlight-part">
        <h1 id="HighLights" class="home-title meeting-highlight">
          <div class="english-name">WONDERFUL PICTURE</div>
          大会亮点
        </h1>
        <HighLights />
        <h1
          class="home-title meeting-video"
          style="margin-top: 290px"
          id="Videos"
        >
          <div class="english-name">NEWS AND INFORMATION</div>
          精彩视频
        </h1>
        <Videos />
      </div>
      <h1 class="home-title meeting-schedule" id="Schedule">
        <div class="english-name">CONFERENCE AGENDA</div>
        大会议程
      </h1>
      <Schedules />
      <h1 id="Guest" class="home-title meeting-guest">
        <div class="english-name">HONOURED GUEST</div>
        重磅嘉宾
      </h1>
      <Guests />

      <!-- 以下为资料看点部分的内容 -->
      <!-- <h1 id="dataPoints" class="home-title meeting-guest top200 btm90">
        <div class="english-name">CONFERENCE HIGHLIGHTS</div>
        资料看点
      </h1>
      <div id="dataPoints" class="top200 btm90">
        <DataPoints />
      </div> -->
      
      <!-- <div style="height: 500px; background: orange"></div> -->

      <h1 id="dataPoints" class="home-title meeting-guest top200 btm180">
        <div class="english-name">WONDERFUL ARTICLE</div>
        精彩干货
      </h1>
      <Drygoods />
      <!-- <div style="height: 500px; background: orange"></div> -->

      <h1 class="home-title meeting-guest top200 btm100">
        <div class="english-name">WONDERFUL PICTURE</div>
        精彩图集
      </h1>
      <Pictures />
      <!-- <div style="height: 500px; background: orange"></div> -->

      <h1 class="home-title meeting-guest top200 btm90">
        <div class="english-name">NEWS AND INFORMATION</div>
        新闻资讯
      </h1>
      <!-- <div style="height: 500px; background: orange"></div> -->
      <News />
      <!-- 以上为资料看点部分的内容 -->

      <h1 class="home-title meeting-guest top200">
        <div class="english-name">ASSOCIATED MEDIA</div>
        合作媒体
      </h1>
      <Medias />
    </a-layout-content>
    <a-layout-footer class="page-footer">
      <Footer></Footer>
    </a-layout-footer>
  </a-layout>
</template>

<script>
import Footer from "./Footer.vue";
import Description from "@/components/home-description.vue";
import HighLights from "@/components/home-highlights.vue";
import Medias from "@/components/home-medias.vue";
import Guests from "@/components/home-guests.vue";
import Videos from "@/components/home-videos.vue";
import Schedules from "@/components/home-schedules.vue";
// import DataPoints from "@/components/home-data-points.vue";
import Drygoods from "@/components/home-wonderful-goods.vue";
import Pictures from "@/components/home-pictures.vue";
import News from "@/components/home-news-information.vue";

export default {
  components: {
    Footer,
    Description,
    HighLights,
    Medias,
    Guests,
    Videos,
    Schedules,
    // DataPoints,
    Drygoods,
    Pictures,
    News
  },
  data() {
    return {
      tabKey: ["1"],
    };
  },
  methods: {
    scrollToAnchor(targetID) {
      const element = document.querySelector(targetID);
      window.scrollTo(0, element.offsetTop - 240);
    },
    login() {
      window.open(
        "https://sso.yunzhangfang.com/login.html?timestamp=1629442989496&url=https://daizhang.yunzhangfang.com/"
      );
    },
    goVideo() {
      let routeData = this.$router.resolve({
        name: "video",
      });
      window.open(routeData.href, '_blank');
    }
  },
};
</script>
<style lang="less" scoped>
.page-layout {
  background: url("~@/assets/bg.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
/deep/ .ant-menu-horizontal > .ant-menu-item > a {
  color: #fffefe;
  font-size: 20px;
  line-height: 1;
}
/deep/ .ant-menu-horizontal > .ant-menu-item-selected {
  color: #fff;
  font-size: 24px;
  background-color: transparent;
  &::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: #f19956;
    border-radius: 2px;
  }
  > a {
    font-size: 24px;
  }
}
/deep/ .ant-menu-horizontal {
  background-color: transparent;
  margin: 0 auto;
  margin-top: -15px;
}
/deep/ .ant-layout-header {
  background-color: transparent;
}
.page-header {
  position: fixed;
  z-index: 100;
  width: 100%;
  color: #fff;
  height: 120px;
  line-height: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(3, 3, 5, 0.35);

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      margin-right: 100px;
    }
    .header-tail {
      margin-left: 60px;
      display: flex;
      align-items: center;

      .playback {
        width: 120px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        cursor: pointer;
        font-size: 18px;
        background: url("~@/assets/nav-观看回放bg-blue.png") no-repeat center;
        background-size: cover;

        &:hover {
          background: url("~@/assets/nav-观看回放bg-orange.png") no-repeat center;
          background-size: cover;
        }
      }

      > span {
        margin-left: 40px;
        font-size: 20px;
        cursor: pointer;
      }
      > img {
        cursor: pointer;
      }
    }
  }
}
.page-content {
  .highlight-part {
    min-height: 1429px;
    background: url("~@/assets/highlight-bg.png") no-repeat;
    background-size: 100%;
  }
  .banner {
    position: relative;

    .review {
      cursor: pointer;
      position: absolute;
      bottom: 98px;
      left: 50%;
      transform: translateX(-50%);
      width: 185px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 24px;
      color: #fff;
      background: url("~@/assets/home-观看回放bg-blue.png") no-repeat center;
      background-size: cover;

      &:hover {
        background: url("~@/assets/home-观看回放bg-orange.png") no-repeat center;
        background-size: cover;
      }
    } 
    .bg {
      width: 100%;
    }
    .button {
      position: absolute;
      bottom: 200px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
  .home-title {
    font-size: 34px;
    color: #fff;
    position: relative;
    color: #fff;
    text-align: center;
    margin-top: 62px;
    padding-top: 62px;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 4px;
      background: #ffad7c;
      border-radius: 2px;
    }
    .english-name {
      position: absolute;
      top: 42px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      color: #242948;
      z-index: -1;
    }
  }
}
.page-footer {
  height: 580px;
  background: #272d3c;
}
/deep/ .ant-layout-footer {
  padding: 0;
}

.top200 {
  margin-top: 200px !important;
}

.btm90 {
  margin-bottom: 90px;
}

.btm100 {
  margin-bottom: 100px;
}

.btm180 {
  margin-bottom: 180px;
}
</style>
