<template>
    <div class="footer-wrapper">
        <div class="uni-container">
            <img src="@/assets/site-logo.png" alt="" @click="toAds()"/>

            <div class="foot-content">
                <div class="foot-content-item" style="width: 286px">
                    <div class="top">
                        <img src="@/assets/footer-telephone.png" alt="" />
                        <span>联系方式</span>
                    </div>
                    <div class="bottom">400-099-9191</div>
                    <div class="after-line"></div>
                </div>
                <div class="foot-content-item" style="width: 330px">
                    <div class="top">
                        <img src="@/assets/footer-media.png" alt="" />
                        <span>媒体合作</span>
                    </div>
                    <div class="bottom">marketing@yunzhangfang.com</div>
                    <div class="after-line"></div>
                </div>
                <div class="foot-content-item" style="width: 338px">
                    <div class="top">
                        <img src="@/assets/footer-shangwu.png" alt="" />
                        <span>商务合作</span>
                    </div>
                    <div class="bottom">marketing@yunzhangfang.com</div>
                </div>
                <div class="qr">
                    <img class="qrcode" src="@/assets/orcode.png" alt="" />
                    <span>官方微信公众号</span>
                </div>
            </div>
        </div>
        <div class="footer-end">
            Copyright © 云帐房科技有限公司 <a href="https://beian.miit.gov.cn/">网站备案号：苏ICP备15032830号</a>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        toAds() {   
            let routeData = this.$router.resolve({
                name: "ad",
            });
            window.open(routeData.href, '_blank');
        }
    },
};
</script>

<style lang="less" scoped>
.footer-wrapper {
    position: relative;
    height: 100%;
    padding-top: 100px;
}
.foot-content {
    display: flex;
    margin-top: 120px;
    align-items: center;
    position: relative;
    &-item {
        color: #fff;
        position: relative;
        .top {
            span {
                font-size: 20px;
                vertical-align: middle;
                margin-left: 8px;
            }
        }
        .bottom {
            font-size: 18px;
        }
        .after-line {
            position: absolute;
            right: 60px;
            top: 4px;
            background: #535353;
            height: 50px;
            width: 1px;
            border-radius: 1px;
        }
    }
    .qr {
        position: absolute;
        right: 0;
        width: 112px;
        text-align: center;
        .qrcode {
            height: 112px;
        }
        span {
            font-size: 16px;
            color: #ffffff;
        }
    }
}
.footer-end {
    height: 120px;
    line-height: 120px;
    text-align: center;
    color: #999999;
    border-top: 1px solid #535353;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    > a {
      margin-left: 16px;
      color: #999999;
      &:hover {
        color: #1890ff;
      }
    }
}
</style>
