<template>
  <div class="wrapper">
    <div class="invited-media-content">
      <div class="media" v-for="media in mediaList" :key="media.id">
        <img :src="media.img" />
      </div>
    </div>
  </div>
</template>

<script>
// 818财税节专题
import { getLocalJSON } from "@/request/api";
export default {
  data() {
    return {
      mediaList: [],
    };
  },
  created() {
    getLocalJSON("818-media.json").then((res) => {
      const { data } = res;
      this.mediaList = data || [];
    });
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 200px;
  margin-top: 140px;
  .invited-media-content {
    display: flex;
    flex-wrap: wrap;
    .media {
      width: 175px;

      > img {
        width: 100%;
        border-radius: 5px;
      }
      &:not(:nth-child(6n + 1)) {
          margin-left: 30px;
          margin-bottom: 32px;
      }
    }
  }
}
</style>
