<template>
  <div class="all-screen">
    <div class="dry-goods">
      <div class="goods-left"></div>
      <div class="goods-right">
        <div class="entry" @click="download()" v-for="item in drygoodsList" :key="item.id">
            <div class="f-left">
              <img class="num" :src="item.img" alt="">
              {{item.title}}
            </div>
            <div class="f-right">
              <img class="download-icon" src="@/assets/img/drygoods/xiazai.png" alt="">
            </div>
          </div>
          <div class="middle-line"></div>
      </div>
      <div v-if="showQR" class="download-addr">
        <img @click="closeQR()" class="close-btn" src="@/assets/img/drygoods/close-btn.png" alt="">
        <span>请微信扫码获取干货</span>
      </div>
    </div>
  </div>
  
</template>

<script>
import { getLocalJSON } from "@/request/api";

export default {
  data() {
    return {
      showQR: false,
      drygoodsList: []
    }
  },
  created() {
    getLocalJSON("dry-goods.json").then((res) => {
      const { data } = res;
      this.drygoodsList = data || [];
    });
  },
  methods: {

    //点击下载按钮
    download() {
      this.showQR = true;
    },

    //关闭二维码
    closeQR() {
      this.showQR = false;
    }
  },
}
</script>

<style lang="less" scoped>
.all-screen {
  width: 100%;
  height: 610px;
  background: url("~@/assets/img/drygoods/bg.png") no-repeat;
  background-size: cover;
}
.dry-goods {
  max-width: 1250px;
  margin: 0 auto;
  height: 610px;
  position: relative;

  .goods-left {
    position: absolute;
    top: -165px;
    left: -20px;
    width: 318px;
    height: 745px;
    background: url("~@/assets/img/drygoods/bg-left.png") no-repeat;
    background-size: cover;
  }

  .goods-right {
    width: 1000px;
    height: 287px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: 100px;
    right: -280px;
    
    .entry {
      width: 466px;
      height: 30px;
      font-size: 16px;
      color: #FFFFFF;
      font-weight: 500;
      line-height: 30px;
      padding-right: 28px;
      margin-bottom: 48px;
      display: flex;
      justify-content: space-between;

      &:not(:nth-child(2n+1)) {
        margin-left: 58px;
      }

      .f-left {
        .num {
          margin-right: 20px;
        }
      }

      .f-right {
        width: 30px;
        height: 30px;
        line-height: 30px;
        .download-icon {
          width: 26px;
          height: 26px;
        }
      }

      &:hover {
        cursor: pointer;
        color: #4D52FF;
        .f-right {
          .download-icon {
            width: 30px;
            height: 30px;
            content: url("~@/assets/img/drygoods/xiazai-hover.png");
          }
        }
      }

      
    }
    .middle-line {
      width: 1px;
      height: 270px;
      background: #3B40FF;
      position: absolute;
      top: 0;
      left: 48%;
    }
 
  }

  .download-addr {
    position: relative;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    width: 589px;
    height: 311px;
    background: url("~@/assets/img/drygoods/QRcode.png") no-repeat;
    span {
      position: absolute;
      bottom: 28px;
      right: 80px;
      color: #FFFFFF;
      font-size: 16px;
    }

    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 54px;
      right: 14px;
      width: 14px;
      height: 15px;
    }
  }
}

</style>