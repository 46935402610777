<template>
    <div class="video-view" v-if="id === '2'">
        博览会价值：以“税”为中心主题聚焦与税密切相关中心主题，邀请国内财税专家进行政策分析及解读，让财税服务行业从业者了解行业趋势，为战略布局及发展航线提供理论支持及政策依据。嘉宾内容丰富每年近百位财税掌门人发声、分享真知灼见，同台对话，无干货，不分享。内容干货输出的大会。规模破圈共赢每年吸引数千名财税人及跨界生态合作伙伴代表共襄盛会。财税全产业链协同政银校企多领域多行业跨界聚合，推动财税服务行业合作，提供发展思路与链接通道。科技展示数智财税未来驱动力建设工具、产品及科学技术成果集中展示的契机及平台，助力行业数字化转型升级。行业表彰典礼大会上，为在中国财税服务行业生态化、数字化建设过程中，各阶段、各领域脱颖而出的优秀标杆、行业榜样颁发重磅权威奖项，为行业发展树立样板，发挥引领和指导作用。
    </div>
    <div class="video-view" v-else>
        概述：
        中国财税服务博览会，简称税博会（FTSE），是财税服务行业的盛会，是提供特色主题分享、丰富嘉宾阵容和多形式交互平台的博览会。通过财税数智理念、创新商业模式、先进经营策略等多维度分享展示、链接合作，拓宽财税人认知广度与宽度，致力于打破财税服务机构经营限制，引领财税服务行业发展趋势，推动财税服务产业链向荣而生。
        基本信息栏：
        中文名 中国财税服务博览会
        外文名 CHINA FISCAL TAXATION SERVICE EXPO
        简称 税博会（FTSE）
        举办城市 中国上海、中国南京
        主办单位 云帐房网络科技有限公司
        举办时间 8月18日
        吉祥物 云小税
        第一届时间 2020年

        历届大会情况：
        时间 地点 大会名称 主题
        2020年8月18日 中国·上海 2020中国财税服务生态大会暨首届818财税节 链接 · 变革
        2021年9月23日 中国·上海 2021中国财税服务博览会暨第二届818财税节 云创税智 共博未来
        2022年8月18日 中国·南京 2022中国财税服务博览会暨第三届818财税节 合作破逆境 数智创未来

        2020年8月18日《链接 · 变革——2020中国财税服务生态大会》于上海成功举办，财税人相聚在一起，验收服务成果、信息互通，集结银行、院校、园区与各行业代表，共筑生态，创造行业内、行业间合作交流。


        2021年中国财税服务博览会《云创税智 共博未来——2021中国财税服务博览会暨第二届818财税节》在上海举办，通过财税数智理念、创新商业模式、经营策略等多维度的分享展示、链接合作，拓宽财税人认知广度六盘交流。

        2022年中国财税服务博览会《合作破逆境 数智创未来——2022中国财税服务博览会暨第三届818财税节》在南京举办。大会特邀税局权威专家、财税院校学者以及财税服务行业意见领袖代表，为1800+与会财税人打造一场饕餮盛宴。

        博览会价值：
        以“税”为中心主题
        聚焦与税密切相关中心主题，邀请国内财税专家进行政策分析及解读，让财税服务行业从业者了解行业趋势，为战略布局及发展航线提供理论支持及政策依据。

        嘉宾内容丰富
        每年近百位财税掌门人发声、分享真知灼见，同台对话，无干货，不分享。内容干货输出的大会。

        规模破圈共赢
        每年吸引数千名财税人及跨界生态合作伙伴代表共襄盛会。

        财税全产业链协同
        政银校企多领域多行业跨界聚合，推动财税服务行业合作，提供发展思路与链接通道。

        科技展示
        数智财税未来驱动力建设工具、产品及科学技术成果集中展示的契机及平台，助力行业数字化转型升级。

        行业表彰典礼
        大会上，为在中国财税服务行业生态化、数字化建设过程中，各阶段、各领域脱颖而出的优秀标杆、行业榜样颁发重磅权威奖项，为行业发展树立样板，发挥引领和指导作用。
    </div>
</template>

<script>
export default {
    data() {
        return {
            id: '1',
        };
    },
    created() {
    },
    mounted() {
        if (this.$route.query?.id) {
            this.id = this.$route.query.id;
        }
    },
    methods: {
    },
};
</script>

<style lang="less" scoped>
.video-view {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url("~@/assets/img/video/bg.jpg") no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;
    padding: 40px;
}


/deep/ .vjs-poster {
    background-size: cover !important;
}
</style>