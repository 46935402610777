<template>
  <div class="wrapper">
    <div class="inner">
      <a-carousel effect="fade" autoplay>
        <div v-for="(item, index) in len" :key="index">
          <div class="highlight-wrapper">
            <div class="highlight-item" v-for="content in showCurrent(index)" :key="content.id">
              <img class="icon" :src="content.icon" alt="" />
              <h1>{{ content.title }}</h1>
              <div>{{ content.subTitle }}</div>
            </div>
          </div>
        </div>
      </a-carousel>
    </div>
  </div>
</template>

<script>
import icon1 from "@/assets/highlight-icon-1.png";
import icon2 from "@/assets/highlight-icon-2.png";
import icon3 from "@/assets/highlight-icon-3.png";
import icon4 from "@/assets/highlight-icon-4.png";
import icon5 from "@/assets/highlight-icon-5.png";
import icon6 from "@/assets/highlight-icon-6.png";
const data = [
  {
    id: 1,
    title: "超大规模",
    subTitle: "1800+财税人共聚现场",
    icon: icon4,
  },
  {
    id: 2,
    title: "权威发声",
    subTitle: "全国百位财税掌门人同台对话",
    icon: icon5,
  },
  {
    id: 3,
    title: "政银校企",
    subTitle: "四方生态聚合推动纵深合作",
    icon: icon6,
  },
  {
    id: 4,
    title: "以“税”为题",
    subTitle: "聚焦与税密切相关的中心主题",
    icon: icon1,
  },
  {
    id: 5,
    title: "前沿科技",
    subTitle: "数智财税未来，最新科技展现",
    icon: icon2,
  },
  {
    id: 6,
    title: "1+3+5",
    subTitle: "1场主论坛、3场分论坛、5场游学",
    icon: icon3,
  },
];
export default {
  data() {
    return {
      list: data,
      len: data.length - 2,
    };
  },
  methods: {
    showCurrent(index) {
      return this.list.slice(index, index + 3);
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  .inner {
    max-width: 1200px;
    margin: 0 auto;
  }
}
.ant-carousel /deep/ .slick-slide {
  text-align: center;
  height: 410px;
  overflow: hidden;
  padding-top: 80px;
  position: relative;;
  top: 0;
  transition: top .2s linear;
  cursor: pointer;
}

.ant-carousel /deep/ .slick-slide h3 {
  color: #fff;
}
.highlight-wrapper {
  display: flex;
  justify-content: space-between;
  .highlight-item {
    position: relative;
    top: 0;
    width: 378px;
    height: 330px;
    background: url("~@/assets/highlight-item-bg.png") no-repeat;
    background-size: 100%;
    color: #fff;
    text-align: left;
    padding: 128px 0 51px 35px;
    > .icon {
      width: 52px;
      height: 52px;
    }
    > h1 {
      color: #fff;
      line-height: 1;
      margin-top: 25px;
      font-size: 36px;
    }
    > div {
      color: #fff;
      margin-top: 20px;
      font-size: 20px;
    }
    &:hover {
      top: -30px;
    }
  }
}
.ant-carousel /deep/ .slick-dots-bottom {
  bottom: -90px;
}
.ant-carousel /deep/ .slick-dots li {
  margin: 0 14px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #7b7b7b;
}
.ant-carousel /deep/ .slick-dots li.slick-active {
  margin: 0 14px;
  width: 36px;
  height: 12px;
  border-radius: 12px;
  background-color: #3a3ffa;
}
.ant-carousel /deep/ .slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #7b7b7b;
}
.ant-carousel /deep/ .slick-dots li.slick-active button {
  width: 36px;
  height: 12px;
  border-radius: 12px;
  background-color: #3a3ffa;
}
</style>
