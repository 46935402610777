<template>
  <div class="agenda">
    <div class="agenda-left" v-if="selectedList">
      <div
        class="agendaList list-static"
        v-for="item in list"
        :key="item.id"
        :class="{
          'list-0': selectedList.id === 0 && item.id === selectedList.id,
          'list-1': selectedList.id === 1 && item.id === selectedList.id,
          'list-2': selectedList.id === 2 && item.id === selectedList.id,
          'list-3': selectedList.id === 3 && item.id === selectedList.id,
        }"
        @click="selectStyle(item)"
      >
        <span class="agenda-text">{{ item.text }}</span>
      </div>
    </div>
    <div class="agenda-right">
      <div class="agenda-info">
        <!-- <div class="agenda-top"></div> -->
        <img
          class="agenda-top"
          :src="selectedList.banner"
          alt=""
          v-if="selectedList"
        />
        <div class="agenda-bot" v-if="selectedList">
          <vue-scroll :ops="ops" style="width: 100%; height: 100%">
            <template v-if="selectedList.id === 0 || selectedList.id === 3">
              <div
                class="bot-list"
                v-for="(item, index) in selectedList.agendaList"
                :key="index"
              >
                <div class="bot-time">{{ item.time }}</div>
                <div class="bot-line"></div>
                <div class="bot-title text-nowrap" :title="item.title">
                  {{ item.title }}
                </div>
                <div class="bot-cot">
                  <span
                    class="cot-name"
                    :class="{
                      'cot-name-55': !(
                        index === selectedList.agendaList.length - 1 ||
                        index === selectedList.agendaList.length - 2
                      ),
                      'guest-space': item.guest.length === 2 ? true: false
                    }"
                    >{{ item.guest }}
                  </span>
                  <template v-if="item.guestDesc">
                    <span class="cot-line" :class="{'guest-no-left': item.guest.length === 2}"></span>
                    <span
                      class="cot-desc text-nowrap"
                      :title="item.guestDesc"
                      >{{ item.guestDesc }}</span
                    >
                  </template>
                </div>
              </div>
            </template>
            <template v-if="selectedList.id === 1 || selectedList.id === 2">
              <div
                class="table-bot-list"
                v-for="(item, index) in selectedList.agendaTableOrArgueList"
                :key="index"
              >
                <div class="table-bot-time" :class="{ top20: index !== 0 }">
                  {{ item.time }}
                </div>
                <div class="table-bot-line"></div>
                <template v-if="item.prop === 'table'">
                  <div
                    class="table-bot-content"
                    :class="{
                      top20: index !== 0,
                      'table-bot-content2': item.title === '增值专场圆桌',
                    }"
                  >
                    <div class="table-bot-content-left">
                      <img
                        class="table-bot-content-left-icon"
                        src="@/assets/schedule-table-icon.png"
                        alt=""
                      />
                      <div class="table-bot-title" :title="item.title">
                        {{ item.title }}
                      </div>
                      <div class="table-bot-title-desc">
                        {{ item.titleDesc }}
                      </div>
                    </div>

                    <div class="table-bot-content-right">
                      <div
                        class="table-bot-cot"
                        v-for="(i, index2) in item.guestList"
                        :key="index2"
                      >
                        <img
                          v-if="i.isHost"
                          class="table-cot-host-icon"
                          src="@/assets/schedule-host-icon.png"
                          alt=""
                        />
                        <span
                          class="table-cot-name"
                          :class="{ left10: i.isHost, left55: !i.isHost, 'guestname-space': i.guestName.length === 2 ? true: false }"
                          >{{ i.guestName }}</span
                        >
                        <template v-if="i.guestDesc">
                          <span class="table-cot-line" :class="{'guestname-no-left': i.guestName.length === 2}"></span>
                          <span
                            class="table-cot-desc text-nowrap"
                            :title="i.guestDesc"
                            >{{ i.guestDesc }}</span
                          >
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="item.prop === 'argue'">
                  <div class="argue-bot-content">
                    <div class="argue-bot-content-top">
                      <img
                        class="argue-bot-icon"
                        src="@/assets/schedule-vs.png"
                        alt=""
                      />
                      <div class="argue-bot-title" :title="item.title">
                        {{ item.title }}
                      </div>
                      <div class="argue-bot-title-desc">
                        {{ item.titleDesc }}
                      </div>
                    </div>
                    <div class="argue-bot-content-bottom">
                      <div class="left10">
                        <div
                          class="table-bot-cot"
                          v-for="(i, index) in item.guestListArr[0]"
                          :key="index"
                        >
                          <img
                            v-if="i.isHost"
                            class="table-cot-host-icon"
                            src="@/assets/schedule-leader.png"
                            alt=""
                          />
                          <span
                            class="table-cot-name"
                            :class="{ left10: i.isHost, left55: !i.isHost, 'guestname-space': i.guestName.length === 2 ? true: false }"
                            >{{ i.guestName }}</span
                          >
                          <template v-if="i.guestDesc">
                            <span class="table-cot-line" :class="{'guest-no-left': i.guestName.length === 2}"></span>
                            <span
                              class="argue-cot-desc text-nowrap"
                              :title="i.guestDesc"
                              >{{ i.guestDesc }}</span
                            >
                          </template>
                        </div>
                      </div>
                      <div class="right15">
                        <div
                          class="table-bot-cot"
                          v-for="(i, index) in item.guestListArr[1]"
                          :key="index"
                        >
                          <img
                            v-if="i.isHost"
                            class="table-cot-host-icon"
                            src="@/assets/schedule-leader.png"
                            alt=""
                          />
                          <span
                            class="table-cot-name"
                            :class="{ left10: i.isHost, left55: !i.isHost, 'guestname-space': i.guestName.length === 2 ? true: false}"
                            >{{ i.guestName }}</span
                          >
                          <template v-if="i.guestDesc">
                            <span class="table-cot-line" :class="{'guest-no-left': i.guestName.length === 2}"></span>
                            <span
                              class="argue-cot-desc text-nowrap"
                              :title="i.guestDesc"
                              >{{ i.guestDesc }}</span
                            >
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </vue-scroll>
        </div>
      </div>
      <img
        v-if="selectedList"
        class="item-active-icon"
        :class="{
          top0: selectedList.id === 0,
          top1: selectedList.id === 1,
          top2: selectedList.id === 2,
          top3: selectedList.id === 3,
        }"
        src="@/assets/schedule-item-active-icon.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { getLocalJSON } from "@/request/api";

export default {
  data() {
    return {
      list: [],
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          size: "2px", // 轨道宽度
          keepShow: false,
          background: "#DCDCDC",
          opacity: 1,
        },
        bar: {
          size: "4px", // 滚动条宽度
          hoverStyle: true,
          onlyShowBarOnScroll: true, //是否只有滚动的时候才显示滚动条
          keepShow: false, // 滚动条是否一直显示
          background: "#3B40FF", //滚动条颜色
          opacity: 1, //滚动条透明度
          "overflow-x": "hidden",
        },
      },
    };
  },
  computed: {
    selectedList: function () {
      const tempdata = this.list && this.list.filter((item) => item.active);
      return tempdata[0];
    },
  },
  methods: {
    selectStyle(item) {
      this.list.forEach((e) => {
        e.active = false;
      });
      item.active = true;
    },
  },
  created() {
    getLocalJSON("schedules.json").then((res) => {
      const { data } = res;
      this.list = data;
    });
  },
};
</script>

<style lang="less" scoped>
.agenda {
  max-width: 1200px;
  height: 606px;
  margin: 0 auto;
  margin-top: 90px;
  margin-bottom: 200px;
  .agenda-left {
    float: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .agendaList {
      width: 259px;
      height: 123px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
    }
    .list-static {
      background: url("~@/assets/schedule-item-bg.png") no-repeat;
      background-size: cover;
    }

    .list-0 {
      background: url("~@/assets/schedule-item-active-bg0.png") no-repeat;
      background-size: cover;
    }
    .list-1 {
      background: url("~@/assets/schedule-item-active-bg1.png") no-repeat;
      background-size: cover;
    }
    .list-2 {
      background: url("~@/assets/schedule-item-active-bg2.png") no-repeat;
      background-size: cover;
    }
    .list-3 {
      background: url("~@/assets/schedule-item-active-bg3.png") no-repeat;
      background-size: cover;
    }
    .agenda-text {
      font-size: 34px;
      font-weight: 800;
      color: #ffffff;
      line-height: 86px;
      text-shadow: 1px 2px 2px rgba(16, 16, 16, 0.13);
    }
  }
  .agenda-right {
    float: right;
    width: 900px;
    height: 100%;
    background: #ffffff;
    border-radius: 21px;
    position: relative;
    display: flex;
    justify-content: center;
    .item-active-icon {
      position: absolute;
      left: -14px;
      top: 51.5px;
      width: 14px;
      height: 20px;
    }

    .top0 {
      top: 51.5px;
    }
    .top1 {
      top: 212.5px;
    }
    .top2 {
      top: 373.5px;
    }
    .top3 {
      top: 534.5px;
    }
    .agenda-info {
      .agenda-top {
        width: 855px;
        height: 100px;
        // background: #0f0628;
        border-radius: 10px;
        margin-top: 15px;
      }
      .agenda-bot {
        margin-top: 25px;
        overflow: hidden;
        overflow-y: auto;
        height: 432px;
        .bot-list {
          display: flex;
          .bot-time {
            width: 91px;
            height: 13px;
            font-size: 16px;
            font-weight: 500;
            color: #3c40ff;
            line-height: 46px;
            margin-left: 12px;
          }
          .bot-line {
            width: 2px;
            background: #3b40ff;
            margin-left: 44px;
            margin-right: 36px;
          }
          .bot-title {
            width: 350px;
            line-height: 46px;
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            margin-right: 10px;
          }
          .bot-cot {
            display: flex;
            align-items: center;
            .cot-name {
              font-size: 18px;
              color: #666666;
              line-height: 46px;
            }

            .cot-name-55 {
              text-align: right;
              // width: 55px;
            }
            .cot-line {
              display: inline-block;
              width: 2px;
              height: 16px;
              background: #3b40ff;
              border: 1px solid #3b40ff;
              border-radius: 1px;
              margin-left: 18px;
              margin-right: 7px;
            }
            .cot-desc {
              font-size: 14px;
              color: #999999;
              line-height: 46px;
              max-width: 230px;
            }
          }
        }
        .table-bot-list {
          display: flex;
          .table-bot-time {
            width: 91px;
            height: 13px;
            font-size: 16px;
            font-weight: 500;
            color: #3c40ff;
            line-height: 46px;
            margin-left: 12px;
          }
          .table-bot-line {
            width: 2px;
            background: #3b40ff;
            margin-left: 44px;
            margin-right: 20px;
          }
          .table-bot-content {
            width: 660px;
            height: 230px;
            background: url("~@/assets/schedule-table-bg.png") no-repeat;
            background-size: 100%;
            display: flex;
            align-items: center;
            .table-bot-content-left {
              width: 388px;
              height: 104px;
              background: url("~@/assets/schedule-table-left-bg.png") no-repeat;
              background-size: 100%;
              display: flex;
              .table-bot-content-left-icon {
                width: 34px;
                height: 28px;
                margin: 32px 14px 44px 10px;
              }
              .table-bot-title {
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                margin-right: 15px;
                margin-top: 40px;
              }

              .table-bot-title-desc {
                color: #333333;
                margin-top: 45px;
              }
            }
            .table-bot-content-right {
              width: 272px;
            }
          }

          .table-bot-content2 {
            height: 282px;
            background: url("~@/assets/schedule-table-bg2.png") no-repeat;
            background-size: 100%;
          }

          .argue-bot-content {
            .argue-bot-content-top {
              display: flex;
              margin-top: 20px;
              .argue-bot-icon {
                width: 44px;
                height: 30px;
              }
              .argue-bot-title {
                font-size: 22px;
                font-weight: 500;
                color: #333333;
                margin-left: 12px;
              }
              .argue-bot-title-desc {
                font-size: 17px;
                font-weight: 400;
                color: #666666;
                margin-left: 17px;
                margin-top: 8px;
              }
            }

            .argue-bot-content-bottom {
              width: 660px;
              // height: 203px;
              background: url("~@/assets/schedule-argue-bg.png") no-repeat;
              background-size: 100%;
              display: flex;
              align-items: center;
              margin-top: 30px;
              justify-content: space-between;
            }
          }

          .table-bot-cot {
            display: flex;
            align-items: center;
            .table-cot-host-icon {
              width: 45px;
            }
            .table-cot-name {
              font-size: 18px;
              color: #ffffff;
              // width: 55px;
              text-align: right;
            }
            .table-cot-line {
              display: inline-block;
              width: 2px;
              height: 16px;
              background: #3b40ff;
              border: 1px solid #3b40ff;
              border-radius: 1px;
              margin-left: 18px;
              margin-right: 7px;
            }
            .table-cot-desc {
              font-size: 14px;
              color: #ffffff;
              max-width: 125px;
            }
            .argue-cot-desc {
              font-size: 14px;
              color: #ffffff;
              max-width: 95px;
            }
          }
        }
      }
    }
  }
}

.guest-space {
  letter-spacing: 17.5px;
}

.guest-no-left {
  margin-left: 0 !important;
}

.guestname-space {
  letter-spacing: 18px;
}

.guestname-no-left {
  margin-left: 0 !important;
}

.left55 {
  margin-left: 55px;
}

// 滚动条位置
/deep/.__bar-is-vertical {
  right: -1px !important;
  left: -1px !important;
  border-radius: 2px !important;
}
// 隐藏横向滚动条
/deep/.__bar-is-horizontal {
  display: none !important;
}
// 隐藏横向滚动条轨道
/deep/.__rail-is-horizontal {
  display: none !important;
}
</style>
