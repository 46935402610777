<template>
  <div class="pictures-swiper">
    <yzf-swiper :img-list="imgList"></yzf-swiper>
  </div>
</template>

<script>
import yzfSwiper from './yzf-swiper.vue'
import img1 from '@/assets/tax-fair1.jpg'
import img2 from '@/assets/tax-fair2.jpg'
import img3 from '@/assets/tax-fair3.jpg'
import img4 from '@/assets/tax-fair4.jpg'
import img5 from '@/assets/tax-fair5.jpg'
import img6 from '@/assets/tax-fair6.jpg'
import img7 from '@/assets/tax-fair7.jpg'
import img8 from '@/assets/tax-fair8.jpg'
import img9 from '@/assets/tax-fair9.jpg'
import img10 from '@/assets/tax-fair10.jpg'
import img11 from '@/assets/tax-fair11.jpg'
import img12 from '@/assets/tax-fair12.jpg'
export default {
  data() {
    return {
      imgList: [
        {
          id: 1,
          img: img1
        },
        {
          id: 2,
          img: img2
        },
        {
          id: 3,
          img: img3
        },
        {
          id: 4,
          img: img4
        },
        {
          id: 5,
          img: img5
        },
        {
          id: 6,
          img: img6
        },
        {
          id: 7,
          img: img7
        },
        {
          id: 8,
          img: img8
        },
        {
          id: 9,
          img: img9
        },
        {
          id: 10,
          img: img10
        },
        {
          id: 11,
          img: img11
        },
        {
          id: 12,
          img: img12
        }
      ]
    }
  },
  components: { yzfSwiper },

}
</script>

<style lang="less" scoped>
.pictures-swiper {
  max-width: 1200px;
  height: 400px;
  margin: 0 auto;
}
</style>