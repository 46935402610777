<template>
    <div class="yzf-swp">
        <img class="left" :src="left" @click="toLeft()"/>
        <div v-for="(item, index) in list" :key="item.id" :class="'img' + (index+1)">
            <div class="shadow">
                <img :src="item.img"/>
            </div>
        </div>
        <img class="right" :src="right" @click="toRight()" />
    </div>
</template>

<script>

import left from "@/assets/left.png";
import right from "@/assets/right.png";
// import leftActive from "@assets/left-active.png";
// import rightActive from "@assets/right-active.png";
export default {
    name: "YzfSwiper",
    props: {
        imgList: {
            type: Array,
            default: function() {
                return []
            },
            require: true,
        }
    },
    data() {
        return {
            allNumLen: 0,
            list: [],
            left: left,
            right: right, 
            position: 0, //记录起始位置
        };
    },
    created() {
        this.initData();
        this.allNumLen = this.imgList.length;
    },
    methods: {
        // 截取五条数据
        initData() {
            this.list = this.imgList.slice(this.position, this.position + 5);
        },
        toLeft() {
            this.position--;
            if(this.position < 0) {
                this.dealSpecial('left', this.position);
                return;
            } else if (this.allNumLen < this.position + 5) {
                this.dealSpecial('right', this.position)
                return;
            }
            this.initData();
        },
        toRight() {
            this.position++;
            if( this.allNumLen < this.position + 5) {
                this.dealSpecial('right', this.position)
                return;
            } else if (this.position < 0) {
                this.dealSpecial('left', this.position);
                return;
            }
            this.initData();
        },
        // 处理边界值
        dealSpecial(type, num) {
            if(type === 'left') {
                if(num <= -5) {
                    this.position = this.allNumLen - 5;
                    this.initData();
                } else {
                    const owe = Math.abs(num);
                    const exist = 5- owe;
                    const listOwe = this.imgList.slice(this.allNumLen - owe, this.allNumLen);
                    const listExist = this.imgList.slice(0, exist);
                    this.list = listOwe.concat(listExist);
                }
            } else {
                if(num >= this.allNumLen) {
                    this.position = 0;
                    this.initData();
                } else {
                    const owe2 = num + 5 - this.allNumLen;
                    const listOwe2 = this.imgList.slice(0, owe2);
                    const listExist2 = this.imgList.slice(num, this.allNumLen);
                    this.list = listExist2.concat(listOwe2);
                }
            }
        }
    },
};
</script>

<style lang="less" scoped>
.yzf-swp {
    width: 100%;
    height: 100%;
    position: relative;

    .left,
    .right {
        widows: 30px;
        height: 52px;
        position: absolute;
        top: calc(50% - 26px);
        z-index: 100;
        cursor: pointer;
    }
    .left {
        left: 42px;
        &:hover{
          content: url('~@/assets/left-active.png')
        }
    }
    .right {
        right: 42px;
        &:hover{
          content: url('~@/assets/right-active.png')
        }
    }

    .img1,
    .img2,
    .img3,
    .img4,
    .img5 {
        height: 100%;
        display: flex;
        align-items: center;
        // opacity: 1;
        .shadow > img {
            width: 100%;
            height: 100%;
        }
    }
    .img1,
    .img5 {
        > .shadow {
            width: 520px;
            height: 330px;
            border-radius: 5px;
            background: #000000;
            > img {
                opacity: 0.6;
            }
        }
        z-index: 77;
    }
    .img2,
    .img4 {
        > .shadow {
            width: 580px;
            height: 368px;
            border-radius: 5px;
            background: #000000;
            > img {
                opacity: 0.8;
            }
        }
        z-index: 88;
    }
    .img3 > .shadow {
        width: 630px;
        height: 400px;
        border-radius: 5px;
        background: #000000;
    }
    .img1 {
        position: absolute;
        left: 0;
        top: 0;
    }
    .img2 {
        position: absolute;
        left: 12%;
        top: 0;
    }
    .img3 {
        position: absolute;
        left: calc(50% - 315px);
        top: 0;
        text-align: center;
        z-index: 99;
    }
    .img4 {
        position: absolute;
        right: 12%;
        top: 0;
    }
    .img5 {
        position: absolute;
        right: 0px;
        top: 0;
    }
}
</style>