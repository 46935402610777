import Vue from "vue";
import VueRouter from 'vue-router';

import Home from "../views/Home.vue";
import Video from '../views/Video-playback.vue'
import Ad from "../views/Ad.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "",
    redirect: "/home",
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/video",
    name: "video",
    component: Video,
  },
  {
    path: "/ad",
    name: "ad",
    component: Ad,
  },
];

const router = new VueRouter({
  routes
})

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this,location.catch(error => error))
}

export default router;
