<template>
  <div class="news-info">
    <div class="news-item" v-for="item of newsList" :key="item.id" @click="goDetail(item.url)">
      <img class="news-cover" :src="item.img" alt="">
      <div class="news-detail">
        <p class="news-title">{{item.title}}</p>
        <p class="news-introduction">{{item.introduction}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getLocalJSON } from "@/request/api";

export default {
  data() {
    return {
      newsList: []
    }
  },
  methods: {
    goDetail(url) {
      window.open(url)
    }
  },
  created() {
    getLocalJSON("news-information.json").then( res => {
      this.newsList = res['data']
    })
  }
}
</script>

<style lang="less" scoped>
.news-info {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  .news-item {
    display: flex;
    align-items: flex-start;

    width: 566px;
    height: 233px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 27px 25px;
    margin-bottom: 40px;

    &:nth-child(odd) {
      margin-right: 48px;
    }

    .news-cover {
      width: 256px;
      height: 180px;
      margin-right: 15px;
      border-radius: 5px;
    }

    .news-detail {
      width: 244px;
      padding-top: 9px;
      color: #000000;
      text-align: left;

      .news-title {
        font-size: 22px;
        font-weight: bolder;
        margin-bottom: 22px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .news-introduction {
        font-size: 14px;
        font-weight: 400;
        line-height: 27px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
      }
    }

    &:hover {
      cursor: pointer;
      background: url("~@/assets/img/news/新闻-hover-bg.png") no-repeat;
      background-size: cover;
      .news-detail {
        color: #FFFFFF;
      }
      
    }
  }
}

</style>