<template>
  <div class="wrapper">
    <a-carousel effect="fade" autoplay>
      <div v-for="i in count" :key="i">
        <div class="guests">
          <div class="guest" v-for="guest in getCurrentAvatars(i)" :key="guest.id">
            <div class="avatar-wrapper">
              <img :src="guest.avatar" alt="" />
            </div>
            <div class="name">{{ guest.name }}</div>
            <div class="identity">{{ guest.identity }}</div>
          </div>
        </div>
      </div>
    </a-carousel>
  </div>
</template>

<script>
import { getLocalJSON } from "@/request/api";
export default {
  data() {
    return {
      guests: [],
      count: 0,
    };
  },
  methods: {
    getCurrentAvatars(index) {
      return this.guests.slice((index - 1) * 10, index * 10);
    },
  },
  created() {
    getLocalJSON("guests.json").then((res) => {
      const { data } = res;
      this.guests = data;
      this.count = Math.ceil(data.length / 10);
    });
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  max-width: 1200px;
  margin: 0 auto;

  .guests {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 60px;
    .guest {
      // margin-bottom: 40px;
      color: #fff;
      text-align: center;
      position: relative;
      top: 0;
      transition: top .6s cubic-bezier(0.39, 0.575, 0.565, 1);
      cursor: pointer;
      .avatar-wrapper {
        width: 180px;
        height: 180px;
        padding: 2px;
        background: url("~@/assets/guest-bg.png") no-repeat;
        background-size: 100%;
        text-align: center;
        position: relative;
        cursor: pointer;
        img {
          border-radius: 50%;
          width: 145px;
          height: 145px;
          display: block;
          margin: 0 auto;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .name {
        font-size: 22px;
        margin-top: 18px;
        line-height: 1;
      }
      .identity {
        margin-top: 20px;
        font-size: 13px;
        line-height: 1;
        padding-bottom: 40px;
        max-width: 180px;
      }
      &:not(:nth-child(5n + 1)) {
        margin-left: 65px;
      }
      &:hover {
        top: -58px;
        .avatar-wrapper {
          width: 180px;
          height: 180px;
          background: url("~@/assets/guest-active-bg.png") no-repeat;
          background-size: 100%;
        }
        .name {
          color: #3c40ff;
        }
        .identity {
          color: #3c40ff;
        }
      }
    }
  }
}
.ant-carousel /deep/ .slick-dots-bottom {
  bottom: -24px;
}
.ant-carousel /deep/ .slick-dots li {
  margin: 0 14px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #7b7b7b;
}
.ant-carousel /deep/ .slick-dots li.slick-active {
  margin: 0 14px;
  width: 36px;
  height: 12px;
  border-radius: 12px;
  background-color: #3a3ffa;
}
.ant-carousel /deep/ .slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #7b7b7b;
}
.ant-carousel /deep/ .slick-dots li.slick-active button {
  width: 36px;
  height: 12px;
  border-radius: 12px;
  background-color: #3a3ffa;
}
</style>
