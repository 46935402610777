<template>
    <div class="video-view">
        <div class="play-area">
            <div class="video-left">
            
                <div class="video-title">
                    <p>{{currentTitle}}：{{currentSubTitle}}</p>
                    <img @click="playback()" class="playback-btn" :src="playbackBtnUrl" alt="">
                </div>
                <div class="video-date">
                  <div class="video-date-left">
                    <img src="@/assets/img/video/时钟icon.jpg" alt="">
                    {{currentDate}}
                  </div>
                </div>
                <div class="playing">
                    <video ref="myVideo" class="video-js vjs-big-play-centered" v-if="currentUrl" @timeupdate="timeupdate">
                        <source :src="currentUrl" type="video/mp4" />
                    </video>
                </div>

                <div class="share-btn">
                  <div class="shareQR">
                 </div>
                  <span><img src="@/assets/img/video/share.png" alt="">分享</span>
                </div>
            </div>
            <div class="video-right">
                <div class="list-title">
                  <img src="@/assets/img/video/电影icon.jpg" alt="">
                  精彩回顾
                </div>
                <div class="video-list">
                    <div class="video-item" v-for="item of postList" :class="item.title === currentTitle ? 'video-active' : ''" :key="item.id" @click="switchVideo(item)">
                      <div class="video-cover">
                        <img :src="item.cover" alt="">
                        <span class="video-duration">{{item.duration}}</span>
                      </div>
                      <div class="video-titles">
                        <p class="title">{{item.title}}</p>
                        <p class="sub-title">{{item.subTitle}}</p>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getLocalJSON } from "@/request/api";
import playbackDisabled from '@/assets/img/video/playback-disabled.png'
import playbackLight from '@/assets/img/video/playback-light.png'
export default {
    data() {
        return {
            currentUrl: null,
            currentTitle: "",
            currentSubTitle: "",
            currentDate: "",
            postList: [],
            play: null,
            canReplay: false, //是否可以点击回放按钮
            playbackBtnUrl: playbackDisabled
        };
    },
    created() {
      getLocalJSON("playback-video.json").then( res => {
      this.postList = res['data']
      this.currentUrl = this.postList[0].url;
      this.currentTitle = this.postList[0].title;
      this.currentSubTitle = this.postList[0].subTitle;
      this.currentDate = this.postList[0].date;
      this.initVideo();
    })
    },
    mounted() {
      
    },
    beforeDestroy() {
        if (this.play) {
            this.play.dispose()
        }
    },
    methods: {
        initVideo() {
             this.$nextTick(() => {
                //初始化视频方法
                this.play = this.$video(this.$refs.myVideo, {
                    //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
                    controls: true,
                    //自动播放属性,muted:静音播放
                    muted: false, // 是否静音
                    language: "zh-CN", // 设置语言
                    //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
                    preload: "auto",
                    //设置视频播放器的显示宽度（以像素为单位）
                    width: "100%",
                    //设置视频播放器的显示高度（以像素为单位）
                    height: "100%",
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    poster: this.postList[0].cover, //视频的封面
                    controlBar: {
                        currentTimeDisplay: true, //当前播放事件
                        timeDivider: true, //当前播放时间与总时间的斜杆分隔符
                        durationDisplay: true, //是否显示总时间
                        remainingTimeDisplay: false, //是否显示剩下的时间，该选项与是否显示总时间二选一
                        volumePanel: {
                            //音量组件
                            inline: false, //可以调整方向为纵向或者横向
                        },
                        AudioTrackButton: true,
                        children: [
                            //children数组这是controlbar的子元素顺序
                            {
                                name: "playToggle",
                            },
                            {
                                name: "currentTimeDisplay",
                            },
                            {
                                name: "progressControl",
                            },
                            {
                                name: "durationDisplay",
                            },
                            {
                                name: "playbackRateMenuButton",
                                // playbackRates: [0.5, 1, 1.5, 2],
                            },
                            {
                                name: "volumePanel",
                            },
                            {
                                name: "FullscreenToggle",
                            },
                        ],
                    },
                });
            });
        },
        //切换视频
        switchVideo(i) {
          this.$refs.myVideo.src = i.url;
          this.canReplay = false;
          this.currentTitle = i.title;
          this.currentSubTitle = i.subTitle;
          this.currentDate = i.date;
          // this.$refs.myVideo.poster = this.postList[1].img;
          this.$refs.myVideo.play();
          this.resetPlaybackBtn()
        },
        //判断视频播放时长
        timeupdate(e) {
          var minutes = Math.floor(e.target.currentTime / 60);   
          var seconds = Math.floor(e.target.currentTime - minutes * 60)
          if(minutes >= 1 || seconds > 10) {
            this.canReplay = true;
            this.playbackBtnUrl = playbackLight
          } else {
            this.canReplay = false;
            this.playbackBtnUrl = playbackDisabled
          }

        },

        //重置回放按钮状态
        resetPlaybackBtn() {
          this.playbackBtnUrl = playbackDisabled;
          this.canReplay = false;
        },

        //回放
        playback() {
          if(this.canReplay === false) {
            return
          } else {
            this.play.currentTime(0);
            this.resetPlaybackBtn()
          }
          
        }
    },
};
</script>

<style lang="less" scoped>

.video-view {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url("~@/assets/img/video/bg.jpg") no-repeat;
    background-size: cover;

    .play-area {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 1200px;
        height: 687px;

        display: flex;

        .video-left {
            position: relative;
            width: 807px;
            height: 100%;
            background: #1a1e38;
            margin-right: 20px;
            padding: 40px 20px;

            .video-title {
                width: 100%;
                height: 38px;
                margin-bottom: 15px;
                font-size: 26px;
                color: #FFFFFF;
                display: flex;
                justify-content: space-between;

                .playback-btn {
                  width: 101px;
                  height: 38px;
                }
            }

            .video-date {
                display: flex;
                justify-content: space-between;
                text-align: left;
                width: 100%;
                height: 20px;
                line-height: 20px;
                padding-right: 22px;
                margin-bottom: 46px;
                font-size: 16px;
                color: #999999;

                .video-date-left {
                  >img {
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                  }
                }
            }

            .playing {
                width: 100%;
                height: 431px;
                position: relative;
                margin-bottom: 30px;
            }

            .share-btn {
                  position: relative;
                  float: right;
                  width: 60px;
                  text-align: right;

                  .shareQR {
                    display: none;
                    z-index: 10;
                    position: absolute;
                    top: -250px;
                    right: -20px;
                    width: 418px;
                    height: 223px;
                    background: url("~@/assets/img/video/shareQR.png") no-repeat;
                    background-size: cover; 
                  }

                  &:hover {
                    .shareQR {
                      display: block;
                    }
                  }

                  >span {
                    cursor: pointer;
                    color: #FFFFFF;
                    font-size: 16px;
                    >img {
                      width: 19px;
                      height: 19px;
                      margin-right: 8px;
                    }
                  }
                  
              }
        }

        .video-right {
            width: 373px;
            height: 100%;
            background: #1a1e38;
            padding: 36px 0 61px 20px;

            .list-title {
                width: 100%;
                height: 26px;
                line-height: 26px;
                margin-bottom: 40px;
                padding-right: 20px;
                font-size: 24px;
                color: #FFFFFF;

                img {
                  width: 26px;
                  height: 26px;
                  margin-right: 10px;
                }
            }

            .video-list {
                width: 100%;
                height: 524px;
                overflow: auto;

                // 修改滚动条样式
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #3b40ff;
                    border-radius: 2px;
                }
                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                .video-item {
                    width: 100%;
                    height: 101px;
                    margin-bottom: 40px;
                    padding-right: 8px;
                    display: flex;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .video-cover {
                      position: relative;
                      margin-right: 12px;
                      width: 201px;
                      height: 101px;
                      img {
                        width: 201px;
                        height: 101px;
                        border-radius: 10px;
                      }
                      .video-duration {
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        font-size: 16px;
                        color: #FFFFFF;
                      }
                    }

                    .video-titles {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      text-align: left;
                      color: #FFFFFF;
                      .title {
                        font-size: 18px;
                        margin-bottom: 10px;
                      }

                      .sub-title {
                        font-size: 14px;
                      }
                    }

                    &:hover {
                      cursor: pointer;
                      .video-titles {
                        color: #FF896C;
                      }
                    }
                }
            }
        }
    }
}

.video-active {
  .video-titles {
    color: #FF896C !important;
  }
}

/deep/ .vjs-poster {
  background-size: cover !important;
}
</style>