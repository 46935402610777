<template>
  <div class="wrapper">
    <div class="inner">
      <div class="video-wrapper">
        <template v-for="item in list">
          <video
            :key="item.id"
            v-if="selectedVideo.id === item.id"
            id="showVideo"
            width="100%"
            height="100%"
            :poster="item.bigImgUrl"
            oncontextmenu="return false;"
            style="object-fit: fill"
          >
            <source :src="item.videoUrl" />
            Your browser does not support the video tag.
          </video>
        </template>

        <img
          v-if="startBtnVisible"
          class="start-btn"
          src="@/assets/video-start-btn2.png"
          alt=""
          @click="play('showVideo')"
        />
        <img
          v-if="stopBtnVisible"
          class="stop-btn"
          src="@/assets/video-stop-btn.png"
          alt=""
          @click="pause('showVideo')"
        />
        <span class="video-title">
          {{ selectedVideo.title }}
        </span>
      </div>
      <div class="videos-img">
        <div
          class="videos-img-item"
          v-for="item in list"
          :key="item.id"
          :class="{ active: item.active }"
          @click="selectStyle(item)"
        >
          <img class="item-img" :src="item.smallImgUrl" alt="" />
          <span>{{ item.title }}</span>
          <img
            v-if="item.active"
            class="item-active-icon"
            src="@/assets/video-item-active-icon.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sImg1 from "@/assets/video-small-img1.png";
import sImg2 from "@/assets/video-small-img2.png";
import sImg3 from "@/assets/video-small-img3.png";
import bigImg1 from "@/assets/video-big-img1.png";
import bigImg2 from "@/assets/video-big-img2.png";
import bigImg3 from "@/assets/video-big-img3.png";
const imgData = [
  {
    id: 1,
    title: "FTSE2021花絮——一览税博会精彩",
    smallImgUrl: sImg1,
    bigImgUrl: bigImg1,
    active: true,
    videoUrl:
      "https://1257122416.vod2.myqcloud.com/28b0e9c8vodsh1257122416/10d83f10387702292617065334/pkaBbX2n4IsA.mp4",
  },
  {
    id: 2,
    title: "财税人之歌——财税服务行业主题曲首发",
    smallImgUrl: sImg2,
    bigImgUrl: bigImg2,
    active: false,
    videoUrl:
      "https://1257122416.vod2.myqcloud.com/28b0e9c8vodsh1257122416/d6bc743a387702292616893264/zkVm1yxtbsgA.mp4  ",
  },
  {
    id: 3,
    title: "FTSE2021阵容——大咖云集，干货满满",
    smallImgUrl: sImg3,
    bigImgUrl: bigImg3,
    active: false,
    videoUrl:
      "https://1257122416.vod2.myqcloud.com/28b0e9c8vodsh1257122416/10d85896387702292617065934/qlTCUx9Rgd4A.mp4",
  },
];
export default {
  data() {
    return {
      list: imgData,
      selectedVideo: imgData[0],
      startBtnVisible: true,
      stopBtnVisible: false,
    };
  },
  methods: {
    play(id) {
      const videoElement = document.getElementById(id);
      videoElement.play();
      this.startBtnVisible = false;
      this.stopBtnVisible = true;
    },
    pause(id) {
      const videoElement = document.getElementById(id);
      videoElement.pause();
      this.startBtnVisible = true;
      this.stopBtnVisible = false;
    },
    selectStyle(item) {
      this.list.forEach((e) => {
        e.active = false;
      });
      item.active = true;
      this.selectedVideo = item;
      this.startBtnVisible = true;
      this.stopBtnVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  margin-bottom: 200px;
  .inner {
    .video-wrapper {
      border-radius: 10px;
      width: 100%;
      height: 661px;
      position: relative;
      video {
        border-radius: 10px;
      }
      .start-btn {
        position: absolute;
        left: 25px;
        bottom: 24px;
        width: 80px;
        height: 80px;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        cursor: pointer;
      }
      .stop-btn {
        position: absolute;
        left: 25px;
        bottom: 24px;
        // left: 44px;
        // bottom: 0;
        // transform: translate(-50%, -50%);
        cursor: pointer;
        width: 80px;
        height: 80px;
        // width: 40px;
        // height: 40px;
      }
      .video-title {
        position: absolute;
        top: 19px;
        left: 22px;
        font-size: 22px;
        font-weight: 500;
        color: #fff1f1;
        line-height: 36px;
      }
    }
    .videos-img {
      display: flex;
      justify-content: space-between;
      .videos-img-item {
        width: 372px;
        height: 280px;
        background: #ffffff;
        border-radius: 10px;
        margin-top: 77px;
        position: relative;
        cursor: pointer;
        .item-img {
          width: 340px;
          height: 186px;
          border-radius: 5px;
          margin-left: 16px;
          margin-top: 20px;
        }
        span {
          position: absolute;
          left: 16px;
          font-size: 18px;
          bottom: 27px;
        }
        .item-active-icon {
          position: absolute;
          left: 170px;
          top: -20px;
          width: 43px;
          height: 39px;
        }
      }
      .active {
        background: #3b40ff;
      }
    }
  }
}
</style>
