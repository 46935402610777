import Vue from 'vue'
import App from './App.vue'
import 'ant-design-vue/dist/antd.css';
import antd from 'ant-design-vue';
import router from './router/index.js'
import './base.less'
import vuescroll from "vuescroll";
import "vuescroll/dist/vuescroll.css"; 
import Videojs from "video.js";
import "video.js/dist/video-js.css";

Vue.config.productionTip = false;
Vue.prototype.$video = Videojs;

Vue.use(antd);
Vue.use(vuescroll);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
